@import '../../../../scss/theme-bootstrap';

.gnav-account {
  &__content-header {
    .is-signed-in & {
      display: none;
    }
    &-title {
      text-align: $ldirection;
      a {
        text-decoration: none;
        color: $color-black;
      }
      &-toggle {
        &-signin {
          padding-#{$rdirection}: 25px;
          .signin-active & {
            text-decoration: underline;
          }
        }
        &-register {
          .register-active & {
            text-decoration: underline;
            font-weight: bold;
          }
        }
        .is-signed-in & {
          display: none;
        }
      }
    }
  }
  &__content-container {
    padding-top: 25px;
    &-signed-in {
      display: none;
      .is-signed-in & {
        display: block;
        overflow-y: auto;
        padding: 15px 0;
      }
    }
    &-forms {
      padding-bottom: 20px;
      .is-signed-in & {
        display: none;
      }
      overflow-y: auto;
      &-account-links {
        display: none;
        .is-signed-in & {
          display: block;
          li {
            padding-bottom: 15px;
            line-height: get-line-height(12px, 12px);
            a {
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      &-signin-container,
      &-register-container {
        &-required {
          padding-bottom: 25px;
        }
        .error-text,
        .signin_overlay_error_messages {
          color: $color-error;
        }
      }
      &-signin-container-form,
      &-register-container-form {
        input[type='submit'] {
          margin: 25px 0;
          width: 100%;
          height: 60px;
        }
        input[type='text'],
        input[type='password'] {
          &.error {
            border: 1px solid $color-error;
          }
        }
        &-forgot-password {
          padding: 25px 0;
          text-align: center;
        }
        &-field {
          display: block;
          width: 100%;
          margin: 0 auto 12px;
        }
      }
      &-register-container-form {
        &-register-terms {
          input[type='checkbox'] {
            &.error {
              & + span {
                &:before {
                  border: 1px solid $color-error;
                }
              }
            }
          }
          input[type='checkbox'] ~ .label,
          input[type='checkbox'] ~ label {
            @include swap_direction(padding, 0 0 0 35px);
            line-height: get-line-height(12px, 15px);
          }
          p {
            margin: 0;
            padding-top: 5px;
          }
        }
      }
      &-signin,
      &-register {
        border-bottom: 1px solid $color-light-gray;
      }
      &-signin {
        display: none;
        ul.error-messages {
          display: none;
          margin: 0 !important; // It is needed to override style which comes from PG.
          padding-bottom: 24px;
          &.error_messages_display {
            display: block;
          }
          li {
            background: none;
            color: $color-error;
            padding: 0;
          }
        }
        .signin-active & {
          display: block;
        }
      }
      &-register {
        display: none;
        .register-active & {
          display: block;
        }
      }
      .facebook-login {
        position: relative;
        #{$ldirection}: 50%;
        transform: translateX(-50%);
        padding-top: 25px;
        @include breakpoint($landscape-up) {
          position: absolute;
        }
        .fb_iframe_widget {
          display: block;
          z-index: -1;
          iframe {
            position: relative;
          }
        }
        &-button {
          appearance: none;
          background: none;
          border: 0;
          padding: 0;
          height: auto;
          line-height: normal;
          margin-bottom: 24px;
          width: 100%;
          &.enabled-fb-login {
            .fb_iframe_widget {
              z-index: auto;
            }
          }
        }
        .fb-disclaimer-error {
          color: $color-invalid;
          margin-bottom: 15px;
          font-size: 14px;
          text-align: justify;
          a {
            color: $color-invalid;
          }
        }
        .social-login__email-opt-in {
          text-align: $ldirection;
          .social-login__opt-in-label {
            text-align: justify;
          }
        }
      }
    }
  }
  // auth states visibility
  .elc-user-state-anonymous & {
    .hide_anon {
      display: none;
    }
  }
  .elc-user-state-logged-in & {
    .hide_auth {
      display: none;
    }
  }
}

.gnav-util {
  .gnav-util-trigger-label-welcome.gnav-util__icon {
    display: none;
  }
  .is-signed-in {
    .gnav-util {
      &-trigger-label {
        &-account-title {
          display: none;
        }
        &-welcome {
          display: block;
        }
      }
    }
    .gnav-util__icon-account.text-cta{
      display: none;
    }
  }
}
